<template>
  <NuxtPage />
</template>
<script>
export default {
  name: 'App',
  computed: { },
  created() {  },
  data() { return {}; }  
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

body {
  height: 100vh;
  width: 100vw;
}

</style>
