import { default as aboutoRyeoqzq4SMeta } from "/home/cloud/website/website/pages/about.vue?macro=true";
import { default as blocklyeYQzEb1jcUMeta } from "/home/cloud/website/website/pages/demo/blockly.vue?macro=true";
import { default as bookingPVNQVw8YkLMeta } from "/home/cloud/website/website/pages/demo/booking.vue?macro=true";
import { default as combinedOnddb42cTiMeta } from "/home/cloud/website/website/pages/demo/combined.vue?macro=true";
import { default as indexjk8zAmrtt4Meta } from "/home/cloud/website/website/pages/demo/index.vue?macro=true";
import { default as lessonFMc8uxWzV2Meta } from "/home/cloud/website/website/pages/demo/lesson.vue?macro=true";
import { default as live0KV7gpBzyMMeta } from "/home/cloud/website/website/pages/demo/live.vue?macro=true";
import { default as pythonsQdDYieIDRMeta } from "/home/cloud/website/website/pages/demo/python.vue?macro=true";
import { default as samplenybRzOirs4Meta } from "/home/cloud/website/website/pages/demo/sample.vue?macro=true";
import { default as simjEYaasFnIyMeta } from "/home/cloud/website/website/pages/demo/sim.vue?macro=true";
import { default as indexeMud85rQBFMeta } from "/home/cloud/website/website/pages/indaba/index.vue?macro=true";
import { default as IntroTextONTIJhnb8cMeta } from "/home/cloud/website/website/pages/indaba/IntroText.vue?macro=true";
import { default as index_45backupPBP6oyCGI0Meta } from "/home/cloud/website/website/pages/index-backup.vue?macro=true";
import { default as indexAgrHwfv7oaMeta } from "/home/cloud/website/website/pages/index.vue?macro=true";
import { default as pricingm8u52BABVDMeta } from "/home/cloud/website/website/pages/pricing.vue?macro=true";
import { default as privacysOITb9AKvxMeta } from "/home/cloud/website/website/pages/privacy.vue?macro=true";
import { default as terms072cVYckonMeta } from "/home/cloud/website/website/pages/terms.vue?macro=true";
import { default as indexW246jykbosMeta } from "/home/cloud/website/website/pages/test_demo/index.vue?macro=true";
import { default as colorswf4Oqo3ufJMeta } from "/home/cloud/website/website/pages/tests/colors.vue?macro=true";
import { default as apiK5aS60kGHfMeta } from "/home/cloud/website/website/pages/tutorial/api.vue?macro=true";
import { default as blocklyneM5dzDZUnMeta } from "/home/cloud/website/website/pages/tutorial/blockly.vue?macro=true";
import { default as blocklyoldl6nPYZM9YPMeta } from "/home/cloud/website/website/pages/tutorial/blocklyold.vue?macro=true";
import { default as blocklytesteqxCyTM5xEMeta } from "/home/cloud/website/website/pages/tutorial/blocklytest.vue?macro=true";
import { default as feedbackbcGzv1M1uIMeta } from "/home/cloud/website/website/pages/tutorial/feedback.vue?macro=true";
import { default as indexef4DYpMU5pMeta } from "/home/cloud/website/website/pages/tutorial/index.vue?macro=true";
import { default as indexoldMoCCV31GIbMeta } from "/home/cloud/website/website/pages/tutorial/indexold.vue?macro=true";
import { default as simpleZzgU87gr0fMeta } from "/home/cloud/website/website/pages/tutorial/simple.vue?macro=true";
import { default as waitlistpFoZZucJpEMeta } from "/home/cloud/website/website/pages/waitlist.vue?macro=true";
import { default as Blockly_draw_challengeF86a4FrXPbMeta } from "/home/cloud/website/website/pages/workshop/Blockly_draw_challenge.vue?macro=true";
import { default as Blockly_PicknPlace1pOMNWs5E4vMeta } from "/home/cloud/website/website/pages/workshop/Blockly_PicknPlace1.vue?macro=true";
import { default as Blockly_PicknPlace2lX7LyB3ubnMeta } from "/home/cloud/website/website/pages/workshop/Blockly_PicknPlace2.vue?macro=true";
import { default as Blockly_x_axisS73U5qGqgXMeta } from "/home/cloud/website/website/pages/workshop/Blockly_x_axis.vue?macro=true";
import { default as BlocklyIntro1tQBJi3haxCMeta } from "/home/cloud/website/website/pages/workshop/BlocklyIntro1.vue?macro=true";
import { default as BlocklyIntro22EbmGwRv53Meta } from "/home/cloud/website/website/pages/workshop/BlocklyIntro2.vue?macro=true";
import { default as CoordinatesOnDisplayfbY6CeCdzLMeta } from "/home/cloud/website/website/pages/workshop/CoordinatesOnDisplay.vue?macro=true";
import { default as EndPart1QuizNbrF5ufJk2Meta } from "/home/cloud/website/website/pages/workshop/EndPart1Quiz.vue?macro=true";
import { default as EndPartI27cf6qDNG9Meta } from "/home/cloud/website/website/pages/workshop/EndPartI.vue?macro=true";
import { default as EndPartIIprWMiyUwNwMeta } from "/home/cloud/website/website/pages/workshop/EndPartII.vue?macro=true";
import { default as EndTutorialKWsPfrLOnkMeta } from "/home/cloud/website/website/pages/workshop/EndTutorial.vue?macro=true";
import { default as FeedbackKzr7yilLxqMeta } from "/home/cloud/website/website/pages/workshop/Feedback.vue?macro=true";
import { default as FeedbackPrimarygJgoq1YJieMeta } from "/home/cloud/website/website/pages/workshop/FeedbackPrimary.vue?macro=true";
import { default as indexA6lawlokQyMeta } from "/home/cloud/website/website/pages/workshop/index.vue?macro=true";
import { default as IntroBlocklytiNklHHjv0Meta } from "/home/cloud/website/website/pages/workshop/IntroBlockly.vue?macro=true";
import { default as IntroCoordinatestvYQUZ0RYnMeta } from "/home/cloud/website/website/pages/workshop/IntroCoordinates.vue?macro=true";
import { default as IntroGripper1_Opening95NN1s2zJpMeta } from "/home/cloud/website/website/pages/workshop/IntroGripper1_Opening.vue?macro=true";
import { default as IntroGripper2_RotationIUD6icMsY7Meta } from "/home/cloud/website/website/pages/workshop/IntroGripper2_Rotation.vue?macro=true";
import { default as IntroGripper3_Zaxiszf75ElE3wAMeta } from "/home/cloud/website/website/pages/workshop/IntroGripper3_Zaxis.vue?macro=true";
import { default as IntroGripper4_MoveAroundaseOOHjaALMeta } from "/home/cloud/website/website/pages/workshop/IntroGripper4_MoveAround.vue?macro=true";
import { default as IntroPartIIXTlIVpFmVVMeta } from "/home/cloud/website/website/pages/workshop/IntroPartII.vue?macro=true";
import { default as IntroPerceptionEXjokbN3OfMeta } from "/home/cloud/website/website/pages/workshop/IntroPerception.vue?macro=true";
import { default as IntroPerception2QjTyCqMSyKMeta } from "/home/cloud/website/website/pages/workshop/IntroPerception2.vue?macro=true";
import { default as IntroText9mhyj6C6qpMeta } from "/home/cloud/website/website/pages/workshop/IntroText.vue?macro=true";
import { default as IntroText1JyWWVUITauMeta } from "/home/cloud/website/website/pages/workshop/IntroText1.vue?macro=true";
import { default as IntroText25nYe96MIUnMeta } from "/home/cloud/website/website/pages/workshop/IntroText2.vue?macro=true";
import { default as IntroText3GJuPcCdlmCMeta } from "/home/cloud/website/website/pages/workshop/IntroText3.vue?macro=true";
import { default as IntroTextSchoolSbYJZvjss2Meta } from "/home/cloud/website/website/pages/workshop/IntroTextSchool.vue?macro=true";
import { default as LegalpO0FgIE52HMeta } from "/home/cloud/website/website/pages/workshop/Legal.vue?macro=true";
import { default as LiveViewIntroDrawX9kRlq8QlaMeta } from "/home/cloud/website/website/pages/workshop/LiveViewIntroDraw.vue?macro=true";
import { default as Perception_Blockly7FMjUHi7M4Meta } from "/home/cloud/website/website/pages/workshop/Perception_Blockly.vue?macro=true";
import { default as PicknPlace_Teleop1u0sUTEaAnWMeta } from "/home/cloud/website/website/pages/workshop/PicknPlace_Teleop1.vue?macro=true";
import { default as PicknPlace_Teleop2CiHlVVxWIhMeta } from "/home/cloud/website/website/pages/workshop/PicknPlace_Teleop2.vue?macro=true";
import { default as testwritingOQCvYTxTFaMeta } from "/home/cloud/website/website/pages/workshop/testwriting.vue?macro=true";
import { default as Writing_ExampleiKls95tqIIMeta } from "/home/cloud/website/website/pages/workshop/Writing_Example.vue?macro=true";
import { default as Writing0cdt3PZyHVHMeta } from "/home/cloud/website/website/pages/workshop/Writing0.vue?macro=true";
import { default as Writing2_IntrorxpfP0fIq8Meta } from "/home/cloud/website/website/pages/workshop/Writing2_Intro.vue?macro=true";
import { default as Writing3_Task4VkzzZ3Y9VMeta } from "/home/cloud/website/website/pages/workshop/Writing3_Task.vue?macro=true";
import { default as Writing3_TaskCopyfaFtf99Lg2Meta } from "/home/cloud/website/website/pages/workshop/Writing3_TaskCopy.vue?macro=true";
import { default as Writing3_TaskNew4RjskzlUaeMeta } from "/home/cloud/website/website/pages/workshop/Writing3_TaskNew.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/cloud/website/website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "demo-blockly",
    path: "/demo/blockly",
    component: () => import("/home/cloud/website/website/pages/demo/blockly.vue").then(m => m.default || m)
  },
  {
    name: "demo-booking",
    path: "/demo/booking",
    component: () => import("/home/cloud/website/website/pages/demo/booking.vue").then(m => m.default || m)
  },
  {
    name: "demo-combined",
    path: "/demo/combined",
    component: () => import("/home/cloud/website/website/pages/demo/combined.vue").then(m => m.default || m)
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/cloud/website/website/pages/demo/index.vue").then(m => m.default || m)
  },
  {
    name: "demo-lesson",
    path: "/demo/lesson",
    component: () => import("/home/cloud/website/website/pages/demo/lesson.vue").then(m => m.default || m)
  },
  {
    name: "demo-live",
    path: "/demo/live",
    component: () => import("/home/cloud/website/website/pages/demo/live.vue").then(m => m.default || m)
  },
  {
    name: "demo-python",
    path: "/demo/python",
    component: () => import("/home/cloud/website/website/pages/demo/python.vue").then(m => m.default || m)
  },
  {
    name: "demo-sample",
    path: "/demo/sample",
    component: () => import("/home/cloud/website/website/pages/demo/sample.vue").then(m => m.default || m)
  },
  {
    name: "demo-sim",
    path: "/demo/sim",
    component: () => import("/home/cloud/website/website/pages/demo/sim.vue").then(m => m.default || m)
  },
  {
    name: "indaba",
    path: "/indaba",
    component: () => import("/home/cloud/website/website/pages/indaba/index.vue").then(m => m.default || m)
  },
  {
    name: "indaba-IntroText",
    path: "/indaba/IntroText",
    component: () => import("/home/cloud/website/website/pages/indaba/IntroText.vue").then(m => m.default || m)
  },
  {
    name: "index-backup",
    path: "/index-backup",
    component: () => import("/home/cloud/website/website/pages/index-backup.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/cloud/website/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/home/cloud/website/website/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/cloud/website/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/cloud/website/website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "test_demo",
    path: "/test_demo",
    component: () => import("/home/cloud/website/website/pages/test_demo/index.vue").then(m => m.default || m)
  },
  {
    name: "tests-colors",
    path: "/tests/colors",
    component: () => import("/home/cloud/website/website/pages/tests/colors.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-api",
    path: "/tutorial/api",
    component: () => import("/home/cloud/website/website/pages/tutorial/api.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-blockly",
    path: "/tutorial/blockly",
    component: () => import("/home/cloud/website/website/pages/tutorial/blockly.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-blocklyold",
    path: "/tutorial/blocklyold",
    component: () => import("/home/cloud/website/website/pages/tutorial/blocklyold.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-blocklytest",
    path: "/tutorial/blocklytest",
    component: () => import("/home/cloud/website/website/pages/tutorial/blocklytest.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-feedback",
    path: "/tutorial/feedback",
    component: () => import("/home/cloud/website/website/pages/tutorial/feedback.vue").then(m => m.default || m)
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/home/cloud/website/website/pages/tutorial/index.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-indexold",
    path: "/tutorial/indexold",
    component: () => import("/home/cloud/website/website/pages/tutorial/indexold.vue").then(m => m.default || m)
  },
  {
    name: "tutorial-simple",
    path: "/tutorial/simple",
    component: () => import("/home/cloud/website/website/pages/tutorial/simple.vue").then(m => m.default || m)
  },
  {
    name: "waitlist",
    path: "/waitlist",
    component: () => import("/home/cloud/website/website/pages/waitlist.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Blockly_draw_challenge",
    path: "/workshop/Blockly_draw_challenge",
    component: () => import("/home/cloud/website/website/pages/workshop/Blockly_draw_challenge.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Blockly_PicknPlace1",
    path: "/workshop/Blockly_PicknPlace1",
    component: () => import("/home/cloud/website/website/pages/workshop/Blockly_PicknPlace1.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Blockly_PicknPlace2",
    path: "/workshop/Blockly_PicknPlace2",
    component: () => import("/home/cloud/website/website/pages/workshop/Blockly_PicknPlace2.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Blockly_x_axis",
    path: "/workshop/Blockly_x_axis",
    component: () => import("/home/cloud/website/website/pages/workshop/Blockly_x_axis.vue").then(m => m.default || m)
  },
  {
    name: "workshop-BlocklyIntro1",
    path: "/workshop/BlocklyIntro1",
    component: () => import("/home/cloud/website/website/pages/workshop/BlocklyIntro1.vue").then(m => m.default || m)
  },
  {
    name: "workshop-BlocklyIntro2",
    path: "/workshop/BlocklyIntro2",
    component: () => import("/home/cloud/website/website/pages/workshop/BlocklyIntro2.vue").then(m => m.default || m)
  },
  {
    name: "workshop-CoordinatesOnDisplay",
    path: "/workshop/CoordinatesOnDisplay",
    component: () => import("/home/cloud/website/website/pages/workshop/CoordinatesOnDisplay.vue").then(m => m.default || m)
  },
  {
    name: "workshop-EndPart1Quiz",
    path: "/workshop/EndPart1Quiz",
    component: () => import("/home/cloud/website/website/pages/workshop/EndPart1Quiz.vue").then(m => m.default || m)
  },
  {
    name: "workshop-EndPartI",
    path: "/workshop/EndPartI",
    component: () => import("/home/cloud/website/website/pages/workshop/EndPartI.vue").then(m => m.default || m)
  },
  {
    name: "workshop-EndPartII",
    path: "/workshop/EndPartII",
    component: () => import("/home/cloud/website/website/pages/workshop/EndPartII.vue").then(m => m.default || m)
  },
  {
    name: "workshop-EndTutorial",
    path: "/workshop/EndTutorial",
    component: () => import("/home/cloud/website/website/pages/workshop/EndTutorial.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Feedback",
    path: "/workshop/Feedback",
    component: () => import("/home/cloud/website/website/pages/workshop/Feedback.vue").then(m => m.default || m)
  },
  {
    name: "workshop-FeedbackPrimary",
    path: "/workshop/FeedbackPrimary",
    component: () => import("/home/cloud/website/website/pages/workshop/FeedbackPrimary.vue").then(m => m.default || m)
  },
  {
    name: "workshop",
    path: "/workshop",
    component: () => import("/home/cloud/website/website/pages/workshop/index.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroBlockly",
    path: "/workshop/IntroBlockly",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroBlockly.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroCoordinates",
    path: "/workshop/IntroCoordinates",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroCoordinates.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroGripper1_Opening",
    path: "/workshop/IntroGripper1_Opening",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroGripper1_Opening.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroGripper2_Rotation",
    path: "/workshop/IntroGripper2_Rotation",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroGripper2_Rotation.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroGripper3_Zaxis",
    path: "/workshop/IntroGripper3_Zaxis",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroGripper3_Zaxis.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroGripper4_MoveAround",
    path: "/workshop/IntroGripper4_MoveAround",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroGripper4_MoveAround.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroPartII",
    path: "/workshop/IntroPartII",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroPartII.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroPerception",
    path: "/workshop/IntroPerception",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroPerception.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroPerception2",
    path: "/workshop/IntroPerception2",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroPerception2.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroText",
    path: "/workshop/IntroText",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroText.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroText1",
    path: "/workshop/IntroText1",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroText1.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroText2",
    path: "/workshop/IntroText2",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroText2.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroText3",
    path: "/workshop/IntroText3",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroText3.vue").then(m => m.default || m)
  },
  {
    name: "workshop-IntroTextSchool",
    path: "/workshop/IntroTextSchool",
    component: () => import("/home/cloud/website/website/pages/workshop/IntroTextSchool.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Legal",
    path: "/workshop/Legal",
    component: () => import("/home/cloud/website/website/pages/workshop/Legal.vue").then(m => m.default || m)
  },
  {
    name: "workshop-LiveViewIntroDraw",
    path: "/workshop/LiveViewIntroDraw",
    component: () => import("/home/cloud/website/website/pages/workshop/LiveViewIntroDraw.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Perception_Blockly",
    path: "/workshop/Perception_Blockly",
    component: () => import("/home/cloud/website/website/pages/workshop/Perception_Blockly.vue").then(m => m.default || m)
  },
  {
    name: "workshop-PicknPlace_Teleop1",
    path: "/workshop/PicknPlace_Teleop1",
    component: () => import("/home/cloud/website/website/pages/workshop/PicknPlace_Teleop1.vue").then(m => m.default || m)
  },
  {
    name: "workshop-PicknPlace_Teleop2",
    path: "/workshop/PicknPlace_Teleop2",
    component: () => import("/home/cloud/website/website/pages/workshop/PicknPlace_Teleop2.vue").then(m => m.default || m)
  },
  {
    name: "workshop-testwriting",
    path: "/workshop/testwriting",
    component: () => import("/home/cloud/website/website/pages/workshop/testwriting.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing_Example",
    path: "/workshop/Writing_Example",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing_Example.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing0",
    path: "/workshop/Writing0",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing0.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing2_Intro",
    path: "/workshop/Writing2_Intro",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing2_Intro.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing3_Task",
    path: "/workshop/Writing3_Task",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing3_Task.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing3_TaskCopy",
    path: "/workshop/Writing3_TaskCopy",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing3_TaskCopy.vue").then(m => m.default || m)
  },
  {
    name: "workshop-Writing3_TaskNew",
    path: "/workshop/Writing3_TaskNew",
    component: () => import("/home/cloud/website/website/pages/workshop/Writing3_TaskNew.vue").then(m => m.default || m)
  }
]